import axios from "axios"
import { useEffect, useState } from "react";
import {BrowserRouter , Routes ,Route ,NavLink,useLocation,Link} from 'react-router-dom';
import '../css/navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faEnvelope, faEdit, faMoneyCheck ,faHome,faCog,faChevronRight,faUsers} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
  CDBIcon,
  CDBBadge,

  
} from 'cdbreact';

const Navbarr = (props) => {

  const server = props.server;
    const location = useLocation();
    const [infos, setInfo] = useState([]);
    const [items,setItems] = useState([]);
    const [activeItem, setActiveItem] = useState('home');
    const [submenuOpen, setSubmenuOpen] = useState(false);
  const [navbarshow,setNavbar] = useState('navres ');
  const [navbarshow2,setNavbar2] = useState('left');
  const [file , setfile]= useState('');
  useEffect (() => {
    setfile(props.file);
  });

    const toggleSubmenu = () => {
      setSubmenuOpen(!submenuOpen);
    };
  
    const handleItemClick = (itemName) => {
      setActiveItem(itemName);
    };

    const handleLogout = () => {
      axios.get(`${server}/logout`)
          .then(res => {
              if (res.data.Status === 'Success') {
                 window.location.reload();
              } else {
                  alert('Erreur');
              }
          })
          .catch(err => console.error(err)); // Handle errors properly
  }


    useEffect(() => {
  
        axios.get(`${server}/info_u`).then((res) => {
          setInfo(res.data);
          
          });
        }, []);

        const checkedItems = props.mess.filter(item => item.lu === 0 || item.lu === null);


        function show() {
          if (navbarshow ==='navres'){
            setNavbar('');
            setNavbar2('left navshow');
          }
          else{
            setNavbar('navres');
            setNavbar2('left');
          }
        }




    return(
      <>    


<div className={navbarshow2}  >


<CDBSidebar className={navbarshow} textColor="#fff" backgroundColor="rgba(3,78,145,1) " style={{height:'100vh',zIndex:"99"}} >
        <CDBSidebarHeader prefix={<CDBIcon icon="bars" size="lg" />} onClick={show} >
       Menu
        </CDBSidebarHeader>


        <CDBSidebarContent>
          <CDBSidebarMenu>


          {infos.map((info) => (
                    info.role === 'Chef de Service' ? (
                
                      <NavLink to="/Groups/L">
                      <CDBSidebarMenuItem icon="users" className={location.pathname === '/Groups/L' ? 'activee' : 'activeee'} >
                      Gestion des groupes
                      </CDBSidebarMenuItem>
                      </NavLink>
                    ) :(
                        <></>
                    )
                ))}   
          <NavLink to="/Inbox/inbox" style={{textDecoration:'none'}} >           
           

{checkedItems.length=== 0 ? (  <CDBSidebarMenuItem className={location.pathname === '/Inbox/inbox' || location.pathname === '/Inbox/send'|| location.pathname==='/Inbox/save' || location.pathname ==='/Inbox/trash' ? 'activee' : 'activeee'} 
              icon="envelope"
            >
             Messagerie
            </CDBSidebarMenuItem>):(<CDBSidebarMenuItem className={location.pathname === '/Inbox/inbox' || location.pathname === '/Inbox/send'|| location.pathname==='/Inbox/save' || location.pathname ==='/Inbox/trash' ? 'activee' : 'activeee'} 
              suffix={
                <CDBBadge color="danger" size="small" borderType="pill" >
                {checkedItems.length}
                </CDBBadge>
              }
              icon="envelope"
            >
             Messagerie
            </CDBSidebarMenuItem>)}
            </NavLink >



            {infos.map ((info) =>(
  info.role === 'Chef de Service' ? (   <NavLink to="/form">
  <CDBSidebarMenuItem icon="table" className={location.pathname === '/form' ? 'activee' : 'activeee'}>
     Formulaire
  </CDBSidebarMenuItem>
  </NavLink>):(<></>)

))}

            {infos.map((info) => (
                    info.cantine === 'oui' && info.role=== 'Econome' || info.type ==='PROGRAMATION'  ? (



<NavLink to="suiviCant">
<CDBSidebarMenuItem icon="utensils" className={location.pathname === '/suiviCant' ? 'activee' : 'activeee'}  >
Suivi de Cantine
</CDBSidebarMenuItem>
</NavLink>

              
                    ) :(
                        <></>
                    )
                ))}   

                {infos.map((info) => (
                    info.role === 'Econome' || info.type === 'finance' ? (
                
           

                      <NavLink to="/suivi/caisse">
                      <CDBSidebarMenuItem icon="cash-register" className={location.pathname === '/suivi/caisse' || location.pathname === '/suivi/caisseA' ? 'activee' : 'activeee'}  >
                      Suivi de Caisse
                      </CDBSidebarMenuItem>
                      </NavLink>

         ) :(
                        <></>
                    )
                ))}    

{infos.map((info) => (
                    info.type === 'PAIE' || info.type ==='PRIMAIRE' && info.role === 'Directeur' ? (
                
                      <NavLink to="/salary/abs" >
                      <CDBSidebarMenuItem icon="coins" className={location.pathname === '/salary/abs' || location.pathname === '/salary/note' ? 'activee' : 'activeee'} >
                      Salaire
                      </CDBSidebarMenuItem>
                      </NavLink>
                        ) :(
                        <></>
                    )
                ))}   

     
<NavLink to="/Setting">
                      <CDBSidebarMenuItem icon="wrench"  className={location.pathname === '/Setting' ? 'activee' : 'activeee'} >
                      Parametre
                      </CDBSidebarMenuItem>
                      </NavLink>
          
          </CDBSidebarMenu>
        </CDBSidebarContent>
        
        <CDBSidebarFooter >



            <CDBSidebarMenuItem icon="sign-out-alt" onClick={handleLogout}  >
              Deconection
            </CDBSidebarMenuItem>
  

          
        </CDBSidebarFooter>
      </CDBSidebar>

  </div> 



      </>


    )


};
export default Navbarr ;
