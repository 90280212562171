import React, { useEffect, useState, useRef  } from "react";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
  faCheck,
  faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate,useLocation,useParams, Navigate} from "react-router-dom";
import "../../css/messagerie.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
export default function NMessage({server, onSendData }) {



  const { id_m } = useParams();
  const navigate = useNavigate();
  const [load,setLoad]= useState(true);
  const [load2,setLoad2]= useState(false);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showGroupe, setShowGroupe] = useState(false);
  const [showuser, setShowUser] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroupes, setSelectedGroupes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [searchGroupQuery, setSearchGroupQuery] = useState("");
  const [showUsers, setShowUsers] = useState(true);
  const [showGroups, setShowGroups] = useState(false);
const [filee , setfilee]=useState('');

  const [infos, setInfo] = useState([]);
  const [userListt, setUserList] = useState([]);
  const [groupeList, setGroupeList] = useState([]);

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelToken, setCancelToken] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
const [myVariable ,setmyVariable] = useState('');





const [Switch , setSwitch]= useState(true);

useEffect(()=>{
  setmyVariable(filee);
},[filee]);

const [showA, setShowA] = useState(false);
const toggleShowA = () => {
  setShowA(true);

  // After 2 seconds, set showA back to true
  setTimeout(() => {
navigate('/Inbox/send');
  }, 2000);
};

  
useEffect(() => {
  // Utiliser `server` reçu depuis le parent

  
  // Vérifier si onSendData est bien une fonction avant de l'utiliser
  if (filee ==="" ) {
    // Envoyer `myVariable` au composant parent en utilisant la fonction de rappel
    onSendData(myVariable);
    console.log(myVariable);
  } else {
    console.error('onSendData is not a function');
  }
}, [onSendData, server]);

useEffect(() => {
  axios.get(`${server}/user`).then((res) => {
    setUserList(res.data);
    
  });
}, []);



  useEffect(() => {
    axios.get(`${server}/info_u`).then((res) => {
      setInfo(res.data);
    });
  }, []);

  const userList = [];
  infos.map((info) => {
    if (info.role !== 'Chef de Service') {
      userList.push(...userListt.filter((item) => item.role === 'Chef de Service'));
    } else {
      userList.push(...userListt.filter((item) => item.role !== 'Chef de Service'));
    }
  });
  




  const SwitchUser = () => {
    setSwitch(true)
  };
  const SwitchGroupe = () => {
    setSwitch(false)
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleGroupSearchChange = (e) => {
    setSearchGroupQuery(e.target.value);
  };

  const handleUserSelect = (userOrValue) => {
   
      // Cas où l'utilisateur est un objet avec une propriété id
      const isSelected = selectedUsers.some(
        (selectedUser) => selectedUser.id === userOrValue.id
      );
      if (isSelected) {
        setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== userOrValue.id ));
      } else {
        setSelectedUsers([...selectedUsers, userOrValue]);
      }
    
  };
  


  const handleGroupSelect = (groupe) => {
    const isSelected = selectedGroups.some(
      (selectedGroup) => selectedGroup.id === groupe.id
    );
    if (isSelected) {
      setSelectedGroups(selectedGroups.filter((selectedGroup) => selectedGroup.id !== groupe.id));
    } else {
      setSelectedGroups([...selectedGroups, groupe]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleInputChange1 = (e) => {
    setInputValue1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setInputValue2(e.target.value);
  };
  function launch_toast() {
    var x = document.getElementById("toast");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 2000);
  }
  const axiosWithProgress = axios.create();

  axiosWithProgress.defaults.onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(percentCompleted);
  };
  const onUploadProgress = (progressEvent) => {
    const loaded = progressEvent.loaded;
    const total = progressEvent.total;
  
    if (total) {
      const percentCompleted = Math.round((loaded * 100) / total);
      setUploadProgress(percentCompleted);
    }
  };
  const cancelFileUpload = () => {
    if (cancelToken) {
      cancelToken.cancel('Annulation de l\'envoi du fichier');
      setUploadProgress(0);
    }
  
    const fileInput = document.getElementById('myFile');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  setfilee(selectedFile.name);
    if (selectedFile) {
      setSelectedFile(selectedFile);

      setUploadProgress(0);
  

  
      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        axios.post(`${server}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: onUploadProgress,
        });
        
    
      } catch (error) {
        console.error('Erreur lors de l\'upload du fichier:', error);
      }
    }


  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);



  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoad2(true);

    const formData = new FormData();
    const selectedFile = e.target.myFile.files[0];
    let fileName = ""; // Initialisez une variable pour stocker le nom du fichier
  
    if (selectedFile) {

      fileName = selectedFile.name; // Obtenez le nom du fichier et stockez-le dans la variable
      console.log(fileName);
                                           
    }

  
    formData.append("data", JSON.stringify({
      users: selectedUsers,
      groupes: selectedGroups,
      noms: infos,
      value1: inputValue1,
      value2: inputValue2,
      file : fileName,
    }));
  
    function launch_toast() {
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 2000);
    }
    const source = axios.CancelToken.source();
    setCancelToken(source);
  
    axiosWithProgress
      .post(`${server}/send`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Assurez-vous de définir le type de contenu pour FormData
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setMessage("Message Envoyés"); // Utilisez le nom du fichier ici
        setLoad2(false);
        setSelectedUsers([]);
        setSelectedGroupes([]);
        setInputValue1("");
        setInputValue2("");
        setUploadProgress(0);
        toggleShowA();

      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de l'envoi des données :", error);
      });
  };




  useEffect(() => {
    axios.get(`${server}/groupeL`).then((res) => {
      setGroupeList(res.data);
    });
  }, []);
  const filteredUserList = userList.filter((user) => {
    return user.LIBETAB.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const filteredGroupList = groupeList.filter((groupe) =>
  groupe.name.toLowerCase().includes(searchGroupQuery.toLowerCase())
);

  return (
    <>
        <ToastContainer
          className="p-3"
          position='bottom-end'
          style={{ zIndex: 1 }}
        >
<Toast show={showA}>
          <Toast.Header style={{background:'#0d6efd',color:'#fff'}}>
          <FontAwesomeIcon icon={faCircleCheck} style={{marginRight:'10px',fontSize:'18px'}}  />
            <strong className="me-auto" >Message</strong>
          </Toast.Header>
          <Toast.Body>{message} !</Toast.Body>
        </Toast>



        </ToastContainer>


<Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>

    <Nav variant="tabs" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#first" onClick={SwitchUser}>Utilisateurs</Nav.Link>
      </Nav.Item>
      {infos.map((info) => (info.role === 'Chef de Service' ? (     
   <Nav.Item>
        <Nav.Link href="#ss" onClick={SwitchGroupe}>groupe</Nav.Link>
      </Nav.Item>):( <></>)))}

    </Nav>
        </Modal.Header>
        <Modal.Body> 

        <Form.Control
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        aria-describedby="passwordHelpBlock"
      style={{marginBottom:'20px'}} />
          <ListGroup as="ul" style={{height:'300px', overflow:"auto"}}>

 
{Switch === true ? (<>     {filteredUserList.map((user) => (
                          <>
                            <ListGroup.Item  key={user.id} style={{cursor:'pointer'}}
                              className={
                                selectedUsers.some(
                                  (selectedUser) =>
                                    selectedUser.id === user.id
                                )
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleUserSelect(user)} as="li"> {user.role} {user.LIBETAB} ({user.username})</ListGroup.Item>
                          </>
                        ))}</>):(
                        
                        
                        
                        
                        
                        <>
                                    {filteredGroupList.map((groupe) => (
                        <>

                       <ListGroup.Item key={groupe.id}  style={{cursor:'pointer'}}
                            className={
                              selectedGroups.some(
                                (selectedGroup) =>
                                  selectedGroup.id === groupe.id
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() => handleGroupSelect(groupe)} as="li">{groupe.name}</ListGroup.Item>



                        </>
                      ))}
                        </>
              
                      )}
                      


    </ListGroup>
    
    </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
<Modal show={show2} onHide={handleClose2} animation={false}>
        <Modal.Header closeButton>


        </Modal.Header>
        <Modal.Body> 

          <ListGroup as="ul" style={{height:'300px', overflow:"auto"}}>

          {selectedUsers.map((user) => (
                          <>
                            <ListGroup.Item  key={user.id} style={{cursor:'pointer'}}
                              className={
                                selectedUsers.some(
                                  (selectedUser) =>
                                    selectedUser.id === user.id
                                )
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleUserSelect(user)} as="li"> {user.role} {user.LIBETAB} ({user.username})</ListGroup.Item>
                          </>
                 

              
                     ) )}
                      



    </ListGroup>
    
    </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
           Fermer
          </Button>
        </Modal.Footer>
      </Modal>


      <Card style={{marginTop:'20px'}}>
      <Card.Header>Nouveau Message</Card.Header>
      <Card.Body>

        <Card.Text>
        A
        <Card style={{ width: '100%' }}>
          
  <Card.Body>

    <div style={{ display: 'flex', overflowX: 'auto' }}>

    {selectedUsers.length > 3 ? (  <> {selectedUsers.slice(0, 3).map((user) => (
    <Button
   size="sm"
   variant="primary"
   key={user.id}
   onClick={() => handleUserSelect(user)}
   style={{ marginLeft: '10px' }}
 >
   {user.role} {user.LIBETAB} 
 </Button>
))}

<Button
 size="sm"
 variant="primary"
 style={{ marginLeft: '10px' }} onClick={handleShow2}
>
+{selectedUsers.length - 3}
</Button>
</>
):(      selectedUsers.map((user) => (

<Button
  size="sm"
  variant="primary"
  key={user.id}
  onClick={() => handleUserSelect(user)}
  style={{ marginLeft: '10px' }}
>
  {user.role} {user.LIBETAB} {user.id} 
</Button>


)))}



      {selectedGroups.map((groupe) => (
        <Button
          size="sm"
          variant="primary"
          key={groupe.id}
          onClick={() => handleGroupSelect(groupe)}
          style={{ marginLeft: '10px' }}
        >
          {groupe.name}
        </Button>
      ))}
      <Button style={{display:'flex',justifyContent:'center',alignItems:'center',marginLeft:'5px'}} variant="primary" size="sm" onClick={handleShow}>
        +
      </Button>
    </div>
  </Card.Body>
</Card>

        <Form className="test"  onSubmit={handleFormSubmit} > 
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>objet</Form.Label>
        <Form.Control type="text" placeholder="objet"  autoComplete="off" onChange={handleInputChange1} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3}       onChange={handleInputChange2}/>
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">

        <Form.Control type="file"  id="myFile" name="filename" onChange={handleFileChange} />
      </Form.Group>
      <div className="d-grid gap-2">



      {
selectedUsers == '' && selectedGroups == '' ? (<><Button style={{cursor:'not-allowed'}} disabled>Selectioner un destinataire</Button></>) :
(<>


{selectedFile !==null && uploadProgress !==100 ? (<>
  <Button style={{cursor:'not-allowed'}} disabled>Envoie du fichier {uploadProgress}% ....</Button>
</>):(<>


  <Button type="sumit">

  {load2 == true ? (<>  <svg className="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                       </svg></>):(<>Envoyer</>)}
    </Button>

</>) }



</>)

}  




    </div>
    </Form>
        </Card.Text>

      </Card.Body>
    </Card>













    </>
  );
}
