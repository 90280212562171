import React, { useEffect, useState} from 'react';
import {BrowserRouter , Routes ,Route ,NavLink,Outlet,Link, useNavigate, Navigate} from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
export default function Register(props){
  const [communes, setComune] = useState([]);
  const [dairas, setDara] = useState([]);
  const [etab, setEtab] = useState([]);
const server = props.server;
  const [showTypeRepas, setShowTypeRepas] = useState(false); 

  useEffect(() => {
    axios.get(`${server}/daira`)
      .then(res => {
        setDara(res.data);
      });
  }, []);



  useEffect(() => {
    axios.get(`${server}/etab`)
      .then(res => {
        setEtab(res.data);
      });
  }, []);


  useEffect(() => {
    axios.get(`${server}/commune`)
      .then(res => {
        setComune(res.data);
      });
  }, []);

  const [infos, setInfos] = useState([]);
  useEffect(() => {
    axios.get(`${server}/info_u`)
      .then(res => {
        setInfos(res.data);
      });
  }, []);






  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    telephone_portable: '',
    appertenace_sente: '',
    Daira : '',
    email: '',
    telephone_fixe: '',
    Commune:'',
    Fax:'',
    cant:'',
    typer:'',
    Fax2:'', 
    NeveleCant:'',
    statuscant:'',
    RaisonCant:''
});

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if Cantine and Daira are not empty
    
    
        try {
          const response = await axios.post(`${server}/updateEtab`, {
            id: formData.id,
            appertenace_sente: formData.appertenace_sente,
            email: formData.email,
            telephone_fixe: formData.telephone_fixe,
            commune: formData.Commune,
            fax: formData.Fax,
            Daira: formData.Daira,
            cant: formData.cant,
            typer: formData.typer,
            Fax2: formData.Fax2,
            NeveleCant: formData.NeveleCant,
            statuscant: formData.statuscant,
            RaisonCant:formData.RaisonCant


          });
          console.log(response.data);
        } catch (error) {
          console.error('Erreur lors de la mise à jour de l\'établissement : ', error);
        }
    
        try {
          const response = await axios.post(`${server}/updateUser`, {
            id: formData.id,
            nom: formData.nom,
            prenom: formData.prenom,
            telephone_portable: formData.telephone_portable,
          });
          console.log(response.data);
          navigate('/inbox');
        } catch (error) {
          console.error('Erreur lors de la mise à jour de l\'utilisateur : ', error);
        }
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
          console.log("Handling change...", e.target.name, e.target.value);
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      
        // Check if Cantine is "oui" to show Type du repas field
        if (name === 'cant') {
          setShowTypeRepas(value === 'oui');
        }

      };
      
    return(
        
         <>











<Card >
      <Card.Header>Inscription</Card.Header>
      <Card.Body style={{maxHeight:'75vh',overflow:'auto'}}>
        <Card.Title>Inscription</Card.Title>
        <Card.Text>

        <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Nom</Form.Label>
          <Form.Control
            required
            placeholder="Nom"
            type="text"
            name="nom" value={formData.nom} onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Prénom</Form.Label>
          <Form.Control
            required
            type="text"
            name="prenom" value={formData.prenom} onChange={handleChange}
            placeholder="Prénom"

          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Téléphone portable</Form.Label>
          <Form.Control
            required
            type="text" name="telephone_portable" value={formData.telephone_portable} onChange={handleChange}
            placeholder="Téléphone portable"
          />
        </Form.Group>
      </Row>


    {infos.map((rel) => (
  rel.role === 'Directeur' ? (
<>
<Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Appartenance senté</Form.Label>
          <Form.Select          name='appertenace_sente'    value={formData.appertenace_sente}
        onChange={handleChange}>
          <option value="">
 /
 </option>
 {etab.map((com) => (
          <option key={com.id} value={com.CODETAB}>
            {com.CODETAB}  {com.LIBETAB}
           
          </option>
        ))}
    </Form.Select>

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>nombre d'éleve</Form.Label>
          <Form.Control
            required
            type="text"
            name="Fax2"
            value={formData.Fax2} onChange={handleChange}

          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Fax</Form.Label>
          <Form.Control
            required
            name="Fax"
            type="text"
            value={formData.Fax} onChange={handleChange}
            placeholder=" Fax"
          />
        </Form.Group>
      </Row>



      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Commune</Form.Label>
          <Form.Select name='Commune'  value={formData.Commune} onChange={handleChange}>
          <option value="">
 /
 </option>
 {communes.map((com) => (
 <option key={com.id} value={com.nom_commune}>
 {com.nom_commune}
 </option>
 ))}
    </Form.Select>

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Daira:</Form.Label>
          <Form.Select name='Daira'  value={formData.Daira} onChange={handleChange}>
          <option value="">
 /
 </option>
 {dairas.map((com) => (
 <option key={com.id} value={com.nom_daira}>
 {com.nom_daira}
 </option>
 ))}
    </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>email</Form.Label>
          <Form.Control
            required
            type="email" name="email" value={formData.email} onChange={handleChange} 
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>telephone fixe</Form.Label>
          <Form.Control
            required
            type="text" name="telephone_fixe" value={formData.telephone_fixe} onChange={handleChange}
          />
        </Form.Group>


      </Row>
      <Form.Group>

<Form.Label>Cantine</Form.Label>
  <Form.Select        name="cant"        value={formData.cant}
onChange={handleChange}>
  <option value="non">non</option>
<option value="oui">oui</option>
</Form.Select>
</Form.Group>
</>
  ) : (
    <div key={rel.id}>

    </div>
  )
))}

{showTypeRepas && (
        <>
            
            <Form.Group>

<Form.Label>status de la cantine </Form.Label>
  <Form.Select name="statuscant"
            value={formData.statuscant}
            onChange={handleChange}>
            <option value="">/</option>
            <option value="fermee">Fermée</option>
            <option value="ouverte">Ouverte</option>
            
</Form.Select>
</Form.Group>




          </>
          )}
{showTypeRepas && formData.statuscant === 'ouverte' ? (
  <>
          <br />
          <select
            style={{ cursor: 'pointer', marginBottom: '10px' }}
            name="typer"
            className="obj2"
            value={formData.typer}
            onChange={handleChange}
          >
            <option value="froid">Froid</option>
            <option value="chaud">Chaud</option>
          </select>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Nombre d'élèves qui profitent de la cantine</Form.Label>
          <Form.Control
            required
          type="text" name="NeveleCant" value={formData.NeveleCant} onChange={handleChange}
          />
        </Form.Group>

   
    
  </>
) : (  

  showTypeRepas && formData.statuscant == 'fermee' ? (
    <div>
      <p>Raisons de la fermeture :</p>
      <input
        type="radio"
        id="raison1"
        name="raisonFermeture"
        value="Raison 1"
        onChange={handleChange}
      />
      <label htmlFor="raison1">Manque de matériel et de fournitures</label><br />
  
      <input
        type="radio"
        id="raison2"
        name="raisonFermeture"
        value="Raison 2"
        onChange={handleChange}
      />
      <label htmlFor="raison2">Position des structures ou des salles</label><br />
  
      <input
        type="radio"
        id="raison3"
        name="raisonFermeture"
        value="Raison 3"
        onChange={handleChange}
      />
      <label htmlFor="raison3">Manque d'approvisionnement</label><br />
        
      <input
        type="radio"
        id="raison4"
        name="raisonFermeture"
        value="Raison 5"
        onChange={handleChange}
      />
      <label htmlFor="raison 5">Manque d'agents</label><br />
      <input
        type="radio"
        id="raison5"
        name="raisonFermeture"
        value="Raison 5"
        onChange={handleChange}
      />
      <label htmlFor="raison4">Autre Raison</label><br />
    </div>
  ):(<></>)
  
)}
      <Button type='submit'>Valider</Button>
    </Form>

        </Card.Text>
      </Card.Body>
    </Card>













   
         </>
    )
}