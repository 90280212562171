

import Navlink from './components/Messagerie/navlink';
import RMessage from './components/Messagerie/MessageR';
import NMessage from './components/Messagerie/Nmessage';
import MessageS from './components/Messagerie/MessageS';
import Mes from './components/Messagerie/Mes';
import Groupe from './components/Groups/test';
import Setting from './components/setting';
import SaveMessage from './components/Messagerie/SaveMessage';
import DelMessage from './components/Messagerie/DelMessage';
import FormSend from './components/From/FromSend';
import NewForm from './components/From/NewForm';
import Navlinkf from './components/From/navlinkf';
import ToastContainer from 'react-bootstrap/ToastContainer';

import Header from './components/header';
import Login from './Login';

import For from './components/From/formval';

import axios from "axios";
import Note from './components/salary/note';
import Abcences from './components/salary/abcences';
import Navlinkg from './components/Groups/navlinkG';
import NavlinkS from './components/salary/navlinkS';
import Navlinke from './components/eta/navlinke';
import Eta from './components/eta/eta';
import Suivi from './components/suivicaisse/suivi';
import SuiviA from './components/suivicaisse/suiviA';
import SuiviCant from './components/suivicantine/cantine';
import NavlinkCant from './components/suivicantine/NavlinkCant';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Navbarr from './components/navbar';
import { useEffect, useState } from "react";
import NavlinkSuivi from './components/suivicaisse/NavlinkSuivi';
import { BrowserRouter as Router, Routes, Route ,Navigate } from 'react-router-dom';
import io from 'socket.io-client';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Toast from 'react-bootstrap/Toast';
import './App.css';
import Register from './components/Register';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


function App() {


 



const [items,setItems] = useState([]);
const [saveM,setSaveM] = useState([]);
const [saveD,setSaveD] = useState([]);




  const [collapse, setCollapse] = useState(false);

  const bgBlack = { backgroundColor: '#000000', color: '#f4f4f4' };
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null);



  const [infos, setInfo] = useState([]);
  const [auth , setAuth] = useState(false);
  const [name,setName] = useState("");
  const [message,setMessage] = useState('');
  const [load,setLoad] = useState(true);
  const [Froms,setForms] = useState([]);
  const [abs, setAbs] = useState();
  const [statut,setSat] =useState([]);
  const [loadmess,setLoadmes] = useState(true);



  const [showB, setShowB] = useState(false);


  const toggleShowB = () => setShowB(!showB);




  const server = 'https://api.tensikde.net';

  const [userList, setUserList] = useState([]);

  
  const idArray = userList.map(item => (item.id));  // Ceci retourne [1]
  const id = idArray.length > 0 ? idArray[0] : null; // Obtenez le premier élément du tableau
  


  const [dataFromChild, setDataFromChild] = useState('');

  // Fonction de rappel pour recevoir les données de ChildComponent




  axios.defaults.withCredentials= true;
  useEffect(()=>{
      axios.get(`${server}`)
      .then(res=>{
          if(res.data.Status === "Success" ){
              setAuth(true);
              setName(res.data.name);
              setLoad(false);
              
          }else{
              setAuth(false);
              setMessage(res.data.Message);
              setLoad(false);
          }
      })
  },[])

  const receiveDataFromChild = (data) => {
    setDataFromChild(data);
  };






  const handleDelete = async (filename) => {
    try {
      await axios.delete(`${server}/delete/${filename}`);
    
      alert('Fichier supprimé avec succès.');
    } catch (error) {
      console.error('Erreur lors de la suppression du fichier:', error);
    }
  };

  








const [inactiveTime, setInactiveTime] = useState(0);

const handleLogout = () => {
  axios.get(`${server}/logout`)
      .then(res => {
          if (res.data.Status === 'Success') {
             window.location.reload();
          } else {
              alert('Erreur');
          }
      })
      .catch(err => console.error(err)); // Handle errors properly
}


useEffect(() => {
  if (auth){
  let timer;

  const resetTimer = () => {
    clearTimeout(timer);
    setInactiveTime(0);
    startTimer();
  };

  const startTimer = () => {
    timer = setTimeout(() => {
      // Déclencher une alerte ou effectuer d'autres actions lorsque l'utilisateur est inactif pendant 10 minutes
      handleLogout();
    }, 10 *60 * 1000); // 10 minutes en millisecondes
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  // Écouter les événements de la souris et du clavier pour détecter l'activité de l'utilisateur
  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);

  // Démarrer le timer initial
  startTimer();

  // Nettoyer les écouteurs d'événements lors du démontage du composant
  return () => {
    document.removeEventListener('mousemove', handleUserActivity);
    document.removeEventListener('keydown', handleUserActivity);
    clearTimeout(timer);
  };
}
}, [inactiveTime,auth]);


  useEffect(() => {
if (auth){
 
  const fetchMessages = async () => {
    axios.get(`${server}/messages`)
      .then(response => {
  
        setItems(response.data);
      })
      .catch(error => {
        console.error('Erreur:', error);
      });
  };


  const fetchMessagesave = async () => {
    axios.get(`${server}/save_m`)
    .then(res => {
      setSaveM(res.data);
    });
  };

  const fetchMessagesdel = async () => {
    axios.get(`${server}/del_m`)
      .then(res => {
        setSaveD(res.data);
        setLoad(false);
      });
  };

  const formS = async () => {
    axios.get(`${server}/formS`)
      .then(res => {
        setForms(res.data);
      });
  };
  
  const fetchDatabs = async () => {
    try {
      axios.get(`${server}/statutSuivi`).then((res) => {
        const foundAbs = res.data.find((sta) => sta.suivi == 0);
        if (foundAbs) {
          setAbs(1); // Update the 'abs' state with the correct value
          console.log(foundAbs);
        } else {
          setAbs(0); // If not found, set 'abs' to 0 (default value)
          console.log(foundAbs);
        }
        setSat(res.data);
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
    fetchDatabs();
    fetchMessages();
    fetchMessagesave();
    fetchMessagesdel();
    formS();
  
    const socket = io.connect(`${server}`);
    socket.emit('exampleEvent', { message: 'Hello from client!' });
  
    socket.on('nouvelUtilisateur', (data) => {
 
      fetchMessages();
  
    });

    socket.on('sup', (data) => {
      console.log(data.idu);
      if (id === data.idu) {
        fetchMessages();
        fetchMessagesdel();
      }
    });
    socket.on('lu', (data) => {

      if (id === data.idu) {
        fetchMessages();

      }
    });
    socket.on('valForm', (data) => {

      if (id === data.idu) {
        fetchMessages();

      }
    });

    socket.on('send', (data) => {
console.log(data.idu);
      if (data.idu.includes(id)) {  // Vérifie si data.idu est présent dans le tableau idu
        fetchMessages();
      }
    });
    socket.on('save_del', (data) => {

      if (id === data.idu) {
        fetchMessages();
        fetchMessagesave();
      }
    });

    socket.on('save1', (data) => {

      if (id === data.idu) {
        fetchMessages();
        fetchMessagesave();
      }
    });

    socket.on('del', (data) => {
      if (id === data.idu) {
        fetchMessagesdel();
      }
    });

    socket.on('abs', (data) => {
        fetchDatabs();
    });

    socket.on('form&Sends', (data) => {
      console.log(data.idu);
      if (data.idu.includes(id)) {  
        fetchMessages();
      }
    });
    
    axios.get(`${server}/info_u`)
        .then((res) => {
            setUserList(res.data);
        })
        .catch(err => console.error(err)); 

  
  }
  
  
}, [auth,id]);

















const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

  useEffect(() => {
    if (auth) {
      axios.get(`${server}/info_u`).then((res) => {
        setInfo(res.data);
        // Log the data received from the API request
      });
    }
  }, [auth]);
  

  if (load){
return (
<>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'90vh' }}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>
</>
)
  } else{
    if (auth){
      return (
  
        <div className="App">
  





        <Router>
   
        <ToastContainer
          className="p-3"
          position='bottom-start'
          style={{ zIndex: 100 }}
        >
        <Toast onClose={toggleShowB} show={showB} animation={false}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Message</strong>
        
          </Toast.Header>
          <Toast.Body>Nouveau Message</Toast.Body>
        </Toast>
        </ToastContainer>
     
        
        
        <div className="main">
        

       
        < Navbarr mess={items} server={server} file={dataFromChild} />
  

        <div className="right">
        <Header server={server} />
        <div className="center">
        <Routes>
      
      
    
        <Route path='/Register' element={<Register server={server}/>}></Route>
   
        
      
          <Route path='/Groups' element={<Navlinkg/>}>
          <Route path='/Groups/L' element={<Groupe server={server}/>}></Route>
          </Route>
      
          <Route path='/salary' element={<NavlinkS/>}>
            <Route path='abs' element={<Abcences server={server} state={statut} />}></Route>
            <Route path='note' element={<Note server={server}/>}></Route>
      
      
          </Route>
      
                        
                               <Route path='/eta' element={<Navlinke />}>
                              <Route path='' element={<Eta server={server}/>}></Route>
                          
                        
                        
                            </Route>
                        
      
      
      
        
      
      
                         
      <Route path='/suivi' element={<NavlinkSuivi/>}>
        
        <Route path='caisse' element={<Suivi server={server}/>}></Route>
        <Route path='caisseA' element={<SuiviA server={server}/>}></Route>
      
      
      
          </Route>
      
        
      
                          
      <Route path='/suiviCant' element={<NavlinkCant/>}>
        
        <Route path='/suiviCant' element={<SuiviCant server={server}/>}></Route>
          </Route>
                            
                  
          
          <Route path='/form' element={<Navlinkf />}>
            <Route path='*' element={<FormSend server={server} mess={Froms} />} />
            <Route path='for/:id_m' element={<For server={server}/>}/>
            <Route path='NewForm' element={<NewForm server={server}/>} />
            <Route index element={<FormSend server={server} mess={Froms} />} />
          </Route>
        <Route path='/Setting' element={<Setting server={server}  />}></Route>
        <Route
      path="*"
      element={<Navigate to="/inbox/inbox" />}
    />
        <Route path="/Inbox/" element={<Navlink />}>
            <Route path="inbox" element={<RMessage mess={items} Loadm={loadmess} server={server}  />}  />
            <Route path="send" element={<MessageS server={server} />} />
            <Route path="mes/:id_m" element={<Mes server={server} />} />
            <Route path="save" element={<SaveMessage server={server} mess={saveM} />} />
            <Route path="message/:id_m" element={<NMessage onSendData={receiveDataFromChild} server={server} />} />
            <Route path="message" element={<NMessage onSendData={receiveDataFromChild} server={server} />} />

            <Route path="trash" element={<DelMessage server={server}  mess={saveD} />} />
          </Route>
              
        </Routes>
        
         </div>
        </div>
        </div>
        
        </Router>
        </div>
      )
    }
    if (!auth){
      return(
        <div className='app'>
        <Router>
        <Routes>
        <Route path="/login" element={< Login server={server}/>}></Route>
        
        <Route path="*" element={< Login server={server}/>}></Route>
        </Routes>
        </Router>
        </div>
    
    
    
      )
    }
      
  
  
  }


}

export default App;
