import {BrowserRouter , Routes ,Route ,NavLink,Outlet, useNavigate} from 'react-router-dom';
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRectangleXmark,faCheck,faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import '../../css/Form.css';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';

import Col from 'react-bootstrap/Col';

import Row from 'react-bootstrap/Row';
export default function NewFrom(props){
  const server = props.server;
  const [userList, setUserList] = useState([

  ]);
  const navigate = useNavigate();
  const [message,setMessage] = useState("");
  const [load2,setLoad2]= useState(false);
  const [load, setLoad] = useState(false);
  const [groupeList, setGroupeList] = useState([]);
  const [infos, setInfo] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [inputValue1, setInputValue1] = useState("");
  const [searchGroupQuery, setSearchGroupQuery] = useState('');
  const [showUsers, setShowUsers] = useState(true);
  const [showGroups, setShowGroups] = useState(false);
  const [formFields, setFormFields] = useState([{ name: '', fieldType: '', fieldOptions: '', user: '' }]);
 

  const [Switch , setSwitch]= useState(true);
  const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const SwitchUser = () => {
    setSwitch(true)
  };
  const SwitchGroupe = () => {
    setSwitch(false)
  };

const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const toggleView = () => {
    setShowUsers(!showUsers);
    setShowGroups(!showGroups);
  };


  const handleGroupSearchChange = (e) => {
    setSearchGroupQuery(e.target.value);
  };
  
 

  const handleUserSelect = (user) => {
    const isSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
    if (isSelected) {
      setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };
  const handleGroupSelect = (groupe) => {
    const isSelected = selectedGroups.some((selectedGroup) => selectedGroup.id === groupe.id);
    if (isSelected) {
      setSelectedGroups(selectedGroups.filter((selectedGroup) => selectedGroup.id !== groupe.id));
    } else {
      setSelectedGroups([...selectedGroups, groupe]);
    }
  };
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const handleInputChange = (index, event) => {
    const values = [...formFields];
    values[index][event.target.name] = event.target.value;
    setFormFields(values);
  };

  const handleAddField = () => {
    setFormFields([...formFields, { name: '', fieldType: '', fieldOptions: '', user: '' }]);
  };


  const handleInputChange1 = (e) => {
    setInputValue1(e.target.value);
  };


  const handleRemoveField = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  };
  const filteredGroupList = groupeList.filter((groupe) =>
  groupe.name.toLowerCase().includes(searchGroupQuery.toLowerCase())
);
  const filteredUserList = userList.filter((user) => {
    return user.LIBETAB.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoad2(true);
    // Créer un objet contenant la liste des utilisateurs et le formulaire
    const formData = {
      users: selectedUsers,
      groupes: selectedGroups,
      noms:infos,
      fields: formFields,
      value1: inputValue1,
    };

    // Envoyer les données du formulaire au backend via Axios
    axios.post(`${server}/form`, formData)
      .then((response) => {
        console.log(response.data);
        setMessage('Message Envoyer');
        setLoad(false);
        // Reset the form fields and selected users
        setSelectedUsers([]);
        setSelectedGroups([]);
        setInputValue1('');

        launch_toast();
        setLoad2(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function launch_toast() {
    var x = document.getElementById("toast")
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", "");navigate('/form')}, 2000);
}
  useEffect(() => {
  
    axios.get(`${server}/groupeL`).then((res) => {
      setGroupeList(res.data);
      
      });
    }, []);

  useEffect(() => {
  
    axios.get(`${server}/info_u`).then((res) => {
      setInfo(res.data);
      
      });
    }, []);
    

  useEffect(() => {
  
    axios.get(`${server}/user`).then((res) => {
      setUserList(res.data);
      
      });
    }, []);

   

  return (
 <>
 
 

 <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>

    <Nav variant="tabs" defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="#first" onClick={SwitchUser}>Utilisateurs</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1" onClick={SwitchGroupe}>Groupes</Nav.Link>
      </Nav.Item>
    </Nav>
        </Modal.Header>
        <Modal.Body> 

        <Form.Control
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        aria-describedby="passwordHelpBlock"
      style={{marginBottom:'20px'}} />
          <ListGroup as="ul" style={{height:'300px', overflow:"auto"}}>

 
{Switch === true ? (<>     {filteredUserList.map((user) => (
                          <>
                            <ListGroup.Item  key={user.id} style={{cursor:'pointer'}}
                              className={
                                selectedUsers.some(
                                  (selectedUser) =>
                                    selectedUser.id === user.id
                                )
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleUserSelect(user)} as="li"> {user.role} {user.LIBETAB} ({user.username})</ListGroup.Item>
                          </>
                        ))}</>):(
                        
                        
                        
                        
                        
                        <>
                                    {filteredGroupList.map((groupe) => (
                        <>


                       <ListGroup.Item key={groupe.id}  style={{cursor:'pointer'}}
                            className={
                              selectedGroups.some(
                                (selectedGroup) =>
                                  selectedGroup.id === groupe.id
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() => handleGroupSelect(groupe)} as="li">{groupe.name}</ListGroup.Item>



                        </>
                      ))}
                        </>
              
                      )}
                      


    </ListGroup>
    
    </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



      <Card style={{marginTop:'20px'}}>
      <Card.Header>Nouveau Message</Card.Header>
      <Card.Body>

        <Card.Text>
        <Card style={{marginBottom:'10px'}}>
       
        <Card.Body>
        <div style={{display:'flex'}}>
        {selectedUsers.map((user) => (
                    <div className="user">
                      <Button size="sm" variant="primary" key={user.id} 
                        onClick={() => handleUserSelect(user)} style={{marginLeft:'10px',zIndex:'100'}}>{user.role} {user.LIBETAB}</Button>
                    </div>
                  ))}
                                    {selectedGroups.map((groupe) => (
       
                    <div className="user">
                      <Button size="sm" variant="primary" key={groupe.id}
                               onClick={() => handleGroupSelect(groupe)} style={{marginLeft:'10px',zIndex:'100'}}>{groupe.name}</Button>
                    </div>

                  ))}
   <Button variant="primary" size="sm" onClick={handleShow}>+</Button>
        </div>
     
       </Card.Body>
  

    </Card>

        </Card.Text>

      <div style={{height:'50vh',overflowY:'auto',overflowX:'hidden'}}>

      <Form  onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Objet</Form.Label>
          <Form.Control                   value={inputValue1}
                  style={{ width: "100%" }}
                  onChange={handleInputChange1}
                  required
                  className="obj"
                  type="text" />
        </Form.Group>


      </Row>

      {formFields.map((field, index) => (
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Nom du champ</Form.Label>
          <Form.Control             type="text"
            id={`name-${index}`}
            name="name"
            value={field.name}
            onChange={(event) => handleInputChange(index, event)} />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Type du champ</Form.Label>
          <Form.Select defaultValue=""id={`fieldType-${index}`}
            name="fieldType"
            value={field.fieldType}
            onChange={(event) => handleInputChange(index, event)}>
          <option value="">Sélectionnez le type</option>
            <option value="text">Texte</option>
            <option value="select">Sélection</option>
          </Form.Select>
        </Form.Group>

        {field.fieldType === 'select' && (
           

                    <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control                type="text"
               id={`fieldOptions-${index}`}
               name="fieldOptions"
               value={field.fieldOptions}
               onChange={(event) => handleInputChange(index, event)}
               placeholder='option1,option2.....' />
        </Form.Group>
      
         )}
             {formFields.length > 1 && (
        <Form.Group className='col-1' controlId="formGridZip" style={{display:'flex',alignItems:'center',marginTop:'30px'}}> 


    

            <Button variant="primary"  type="button" onClick={() => handleRemoveField(index)}>
            X
           </Button>
           </Form.Group>
       )}
<br/>

          {index === formFields.length - 1 && (
                 <Form.Group className='col-1' controlId="formGridZip" style={{display:'flex',alignItems:'center',marginTop:'30px'}} >

          <Button  variant="primary" type="button" onClick={handleAddField}>
          +
         </Button>
              </Form.Group>
          )}

    
      </Row>
))}

{
selectedUsers == '' && selectedGroups == '' ? (<><Button style={{cursor:'not-allowed'}} disabled>Selectioner un destinataire</Button></>) :
(<>





  <Button type="sumit">

  {load2 == true ? (<>  <svg className="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                       </svg></>):(<>Envoyer</>)}
    </Button>

</>) 





}  

    </Form>
      </div>

       
      </Card.Body>
    </Card>











 
 
 
 
 
  
</>
 
 
 
 
 
 
 

  );
};
