import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  faRectangleXmark,
  faCircleCheck,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
function Setting(props) {
  const server = props.server;
  const navigate = useNavigate();

  // États pour les données de l'utilisateur, le message, et les champs de formulaire
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [messagep, setMessageP] = useState("");
  const [messageE, setMessageE] = useState("");

  const [communes, setComune] = useState([]);
  const [dairas, setDara] = useState([]);
  const [cantine, setCantine] = useState("");


  useEffect(() => {
    axios.get(`${server}/daira`)
      .then(res => {
        setDara(res.data);
      });
  }, []);

  useEffect(() => {
    axios.get(`${server}/commune`)
      .then(res => {
        setComune(res.data);
      });
  }, []);



  const [etab, setEtab] = useState([]);
  useEffect(() => {
    axios.get(`${server}/etab`)
      .then(res => {
        setEtab(res.data);
      });
  }, []);


  const [infos, setInfos] = useState([]);
  useEffect(() => {
    axios.get(`${server}/info_u`)
      .then(res => {
        setInfos(res.data);
      });
  }, []);








  // États pour le formulaire de changement de mot de passe
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // États pour le formulaire de profil
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");

  // États pour le formulaire d'établissement
  const [sante, setSante] = useState("");
  const [fax, setFax] = useState("");
  const [commune, setCommune] = useState("");
  const [daira, setDaira] = useState("");
  const [email, setEmail] = useState("");
  const [telFix, setTelFix] = useState("");
  const [typeRepas, setTypeRepas] = useState("");
  const [LIBETAB, setLIBETAB] = useState("");
  const [LIBETABA, setLIBETABA] = useState("");


  function launch_toast() {
    var x = document.getElementById("toast");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 2000);
  }
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      try {
        const response = await axios.post(`${server}/changePassword`, {
          currentPassword,
          newPassword,
        });

        if (response && response.data) {
          setMessage(response.data.message);
          console.log(response.data.message);
          // Mot de passe mis à jour avec succès
          // Réinitialiser les champs du formulaire
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          launch_toast();
          setMessage(<p style={{ color: "green" }}>Mot de passe a été modifié avec succès</p>);
        } else {
          setMessage("Les données de réponse sont indéfinies ou vides.");
        }
      } catch (error) {
        console.error(error.response ? error.response.data.error : "Une erreur s'est produite");
        setMessage(<p style={{ color: "red" }}>Mot de passe actuel est incorrect</p>);
      }  setMessage(<p style={{ color: "black" }}>les champs sont vide</p>);
    } else {
      setMessage(<p style={{ color: "red" }}>le Nouveau mot de passe et la Confirmation sont différents  </p>);
    }
  };

  // Utiliser useEffect pour récupérer les données utilisateur lors du chargement de la page
  useEffect(() => {
    axios.get(`${server}/parametre`)
      .then((res) => {
        setUsers(res.data);
        if (res.data.length > 0) {
          const userData = res.data[0]; // Supposons que vous souhaitez afficher les données du premier utilisateur
          setNom(userData.nom);
          setPrenom(userData.prenom);
          setTelephone(userData.Telephone.toString());
          setSante(userData.sente);
          setFax(userData.fax.toString());
          setCommune(userData.commune);
          setDaira(userData.daira);
          setEmail(userData.email);
          setTelFix(userData.tel_fix);
          setCantine(userData.cantine);
          setTypeRepas(userData.typer);
          setLIBETAB(userData.LIBETAB);
          setLIBETABA(userData.LIBETABA);

        }
      });
  }, []);

  // Fonction pour gérer la soumission du formulaire de mise à jour du profil
  const handleProfileUpdate = async () => {
    try {
      const response = await axios.post(`${server}/up`, {
        id: users[0].id, // Supposons que vous ayez l'ID de l'utilisateur
        nom,
        prenom,
        telephone,
        // Ajoutez d'autres champs que vous souhaitez mettre à jour ici
      });

      if (response && response.data) {
        setMessageP('Modifié avec succès');
        launch_toast()
        setTimeout(function() {
          window.location.reload();
      }, 3000);
         // Données mises à jour avec succès
      } else {
        setMessageP("Les données de réponse sont indéfinies ou vides.");
      }
    } catch (error) {
      console.error(error.response ? error.response.data.error : "Une erreur s'est produite");
    }
  };

  // Fonction pour gérer la soumission du formulaire de mise à jour de l'établissement
// ...
const handleEtablissementUpdate = async () => {
  try {
    const response = await axios.post(`${server}/updateEtablissement`, {
      sante, // Make sure the "sante" field is included here
      fax,
      commune,
      daira,
      email,
      telFix,
      cantine,
      typer: typeRepas,
      LIBETAB,
      LIBETABA
    });

    if (response && response.data) {
      launch_toast();
      setMessageE("Modifié avec succès");
      setTimeout(function() {
        window.location.reload();
    }, 2000);
    } else {
      setMessageE("Les données de réponse sont indéfinies ou vides.");
    }
  } catch (error) {
    console.error(error.response ? error.response.data.error : "Une erreur s'est produite");
  }
};



// ...

<button type="button" onClick={handleEtablissementUpdate}>Validereeee</button>


  // Retourner le contenu JSX du composant
  return (
    <>


<Card >
      <Card.Header>Parametre</Card.Header>
      <Card.Body style={{maxHeight:'75vh',overflow:'auto'}}>
        <Card.Title>Mot de Passe</Card.Title>
        <Card.Text>
        <div id="desc">{message}{messagep}{messageE}</div>
        <Form>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Mot de passe actuel</Form.Label>
          <Form.Control
            required
            placeholder="Mot de passe actuel"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Nouveau mot de passe</Form.Label>
          <Form.Control
            required
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Nouveau mot de passe"

          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Confirmer le nouveau mot de passe</Form.Label>
          <Form.Control
            required
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmer"
          />
        </Form.Group>
      </Row>
      <Button onClick={handleChangePassword}>Changer le mot de passe</Button>
    </Form>


    <Card.Title style={{marginTop:'20px'}}>Profil</Card.Title>

    <Form>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Nom</Form.Label>
          <Form.Control
            required
            placeholder="Nom"
            type="text"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Prenom</Form.Label>
          <Form.Control
            required
            type="text"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}

          />

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Téléphone</Form.Label>
          <Form.Control
            required
            type="text"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            placeholder="Téléphone"
          />
        </Form.Group>
      </Row>
      <Button onClick={handleProfileUpdate}>Valider</Button>
    </Form>





    {infos.map((rel) => (
 rel.role =='Directeur' ? (<>
 
 
 <Card.Title style={{marginTop:'20px'}}>Etablissement</Card.Title>

 
 <Form>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Nom de l'etablissement:</Form.Label>
          <Form.Control
            required
            placeholder="Nom de l'etablissement"
            type="text"
            value={LIBETAB}
            onChange={(e) => setLIBETAB(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Nom de l'etablissement en arabe</Form.Label>
          <Form.Control
            required
            type="text"
            value={LIBETABA}
            onChange={(e) => setLIBETABA(e.target.value)}

          />

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Santé</Form.Label>
          <Form.Select  value={sante}
 onChange={(e) => setSante(e.target.value)} >
          <option value="">
 /
 </option>
          {etab.map((com) => (
 <option key={com.id} value={com.CODETAB}>
 {com.CODETAB} {com.LIBETAB}
 
 </option>
 ))}
    </Form.Select>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Fax:</Form.Label>
          <Form.Control
            required
            placeholder="Nom de l'etablissement"
            type="text"
            value={fax}
            onChange={(e) => setFax(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Commune</Form.Label>
          <Form.Select  value={commune} onChange={(e) => setCommune(e.target.value)}>
          <option value="">
 /
 </option>
 {communes.map((com) => (
 <option key={com.id} value={com.nom_commune}>
 {com.nom_commune}
 </option>
 ))}
    </Form.Select>

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Daira:</Form.Label>
          <Form.Select value={daira} onChange={(e) => setDaira(e.target.value)}>
          <option value="">
 /
 </option>
 {dairas.map((com) => (
 <option key={com.id} value={com.nom_daira}>
 {com.nom_daira}
 </option>
 ))}
    </Form.Select>
        </Form.Group>
      </Row>


      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            placeholder="name@example.com"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Tel Fix</Form.Label>
          <Form.Control
            required
            type="text"
            value={telFix}
            onChange={(e) => setTelFix(e.target.value)}

          />

        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Cantine</Form.Label>
          <Form.Select  value={cantine}
 onChange={(e) => setCantine(e.target.value)} >
 <option value="non">Non</option>
 <option value="oui">Oui</option>
    </Form.Select>
        </Form.Group>
      </Row>





      {cantine === "oui" && ( // Show the "Type de Repas" input if "Cantine" is "oui"

      <Row className="mb-2">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Type de Repas</Form.Label>
          <Form.Select   value={typeRepas}
 onChange={(e) => setTypeRepas(e.target.value)}>
 <option value="froid">Froid</option>
 <option value="chaud">Chaud</option>
    </Form.Select>
        </Form.Group>

      </Row>
 
 )}


      <Button onClick={handleEtablissementUpdate}>Valider</Button>
    </Form>

 
 
 
 
 
 
 
 
 
 
 
 
 

 
 
 
 


 </>):(<></>)
))}






        </Card.Text>
      </Card.Body>
    </Card>

   
    </>
  );
}

export default Setting;
