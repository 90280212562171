import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong , faCircleDown ,faReply,faTrash,faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import icon from '../../asset/inbox.png';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
export default function Mes(props) {
  const Navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputs, setInputs] = useState([]);

  const server = props.server;
const [title,setTilte]=useState('Message');
const { id_m } = useParams();
  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.get(`${server}/mes/${id_m}`).then((res) => {
      setMessages(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get(`${server}/for/${id_m}`).then((res) => {
      setInputs(res.data);
    });
  }, []);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('fr-FR', options);
    return formattedDate;
  }

  const [formValues, setFormValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [numRows, setNumRows] = useState(1);



  const navigate = useNavigate();

  const [showA, setShowA] = useState(false);
  const toggleShowA = () => {
    setShowA(true);
  
    // After 2 seconds, set showA back to true
    setTimeout(() => {
  navigate('/Inbox/inbox');
    }, 2000);
  };
  










  const handleFormSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      formValues: formValues,
      inputs: inputs
    };
    // Envoyer les valeurs des inputs remplis
    axios.post(`${server}/val/${messages.map((item) => (item.id_m))}`,requestData)
      .then((response) => {
        // Gérer la réponse du backend si nécessaire
        console.log(response.data);
        toggleShowA();
      })
      .catch((error) => {
        // Gérer les erreurs si nécessaire
        console.error(error);
      });
  
    // Réinitialiser les valeurs du formulaire
    setFormValues({});
  };
  const handleDelete = () => {

    axios.post(`${server}/mes_del/${id_m}`)
      .then((response) => {
        // Gérer la réponse du backend si nécessaire
        console.log(response.data);
      })
      .catch((error) => {
        // Gérer les erreurs si nécessaire
        console.error(error);
      });
  };
  
  const handleSave = () => {
    axios.post(`${server}/mes_save/${id_m}`)
      .then((response) => {
        // Gérer la réponse du backend si nécessaire
        console.log(response.data);
      })
      .catch((error) => {
        // Gérer les erreurs si nécessaire
        console.error(error);
      });
  };
  
  const handleNumRowsChange = (e) => {
    const { value } = e.target;
    setNumRows(Number(value));
  };
// ...
const mess = messages.map((item) => (item.id_m));

const [trigger, setTrigger] = useState(true);

// Utilisez useEffect pour mettre à jour trigger lorsque lu change


useEffect(() => {
  if (trigger === true ) {
    axios.post(`${server}/lu/${mess}`)
      .then((response) => {
        console.log(response.data);
        setTrigger(false);
      })
      .catch((error) => {
        console.error(error);
      });
    // Réinitialisez le trigger après la requête si nécessaire

  }else{
    console.log('ya rien a voir' )
  }
}, [mess]);


useEffect(() => {
  setFormValues((prevValues) => {
    const updatedValues = Array.isArray(prevValues) ? [...prevValues] : [];
    // Effectuez vos modifications sur updatedValues
    return updatedValues;
  });
}, []);

// ...

const handleDownload = (filename) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = `${server}/download/${filename}`;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};





function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const locale = 'fr-FR'; // Spécifiez la locale française
  const formattedDate = new Date(dateString).toLocaleDateString(locale, options);
  return formattedDate;
}






  return (
    <>

<ToastContainer
          className="p-3"
          position='bottom-end'
          style={{ zIndex: 1 }}
        >
<Toast show={showA}>
          <Toast.Header style={{background:'#0d6efd',color:'#fff'}}>
          <FontAwesomeIcon icon={faCircleCheck} style={{marginRight:'10px',fontSize:'18px'}}  />
            <strong className="me-auto" >Message</strong>
          </Toast.Header>
          <Toast.Body>Formulaire envoyé !</Toast.Body>
        </Toast>



        </ToastContainer>
    
    <Card style={{marginTop:'20px'}}>
      <Card.Header>          

          <div style={{display:'flex',justifyContent:'space-between'}}><div>        <Link to="/Inbox/inbox">
            <FontAwesomeIcon style={{ fontSize: '15px', color: 'rgba(0,0,0,0.5)',marginRight:'10px'}} icon={faLeftLong} />
          </Link>Message</div><div style={{display:'flex'}}> 

  </div></div></Card.Header>
      <Card.Body>
        <Card.Title style={{marginBottom:'20px'}}>Message</Card.Title>
        <Card.Text>
        {messages.map((message, key) => (
          message.formulaire == '1' ? (
            
            <div key={key} style={{width:'95%', margin:'auto'}}> 
     <h4 style={{textAlign:'center',marginBottom:'0'}}>{message.objet}</h4>
                 
     <label htmlFor="numRows">Nombre de lignes :</label>
     <input
              className="obj nn"
                type="number"
                name="numRows"
                id="numRows"
                value={numRows}
                onChange={handleNumRowsChange}
                style={{width:'60px',height:'30px'}}
                required
              />
                           <hr style={{width:'105%',marginLeft:'-2.5%',opacity:'0.3'}} />
            <Form style={{height:'200px' , overflow:'auto'}}>
            <table>
                <thead>
                  <tr>
                   
                    {inputs.map((input, index) => (
                      <th key={index}>{input.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
{Array.from({ length: numRows }).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {inputs.map((input, index) => (
                        <td className="tdc" key={index}>
                          {input.type === 'select' ? (



<Form.Select aria-label="Default select example"      
    
                              name={input.name}
                              id={input.name}
                              required
                              value={formValues[rowIndex] && formValues[rowIndex][index] ? formValues[rowIndex][index] : ''}
                              onChange={(e) => {
                                const { value } = e.target;
                                setFormValues((prevValues) => {
                                  const updatedValues = [...prevValues];
                                  updatedValues[rowIndex] = [...(updatedValues[rowIndex] || [])];
                                  updatedValues[rowIndex][index] = value;
                                  return updatedValues;
                                });
                              }}>
                              <option value=''>Vide</option>
                              {input.options.split(',').map((option, optionIndex) => (
                                <option key={optionIndex} value={option}>{option}</option>
                              ))}
</Form.Select>
                          ) : (
<>


<Row className="mb-3">
        <Form.Group as={Col} controlId="validationCustom03">
<Form.Control placeholder={input.name} required  type ={input.type}
                              name={input.name}
                              id={input.name}
                              value={formValues[rowIndex] && formValues[rowIndex][index] ? formValues[rowIndex][index] : ''}
                              onChange={(e) => {
                                const { value } = e.target;
                                setFormValues((prevValues) => {
                                  const updatedValues = [...prevValues];
                                  updatedValues[rowIndex] = [...(updatedValues[rowIndex] || [])];
                                  updatedValues[rowIndex][index] = value;
                                  return updatedValues;
                                });
                              }} />

        
          </Form.Group>
      </Row>   
         
</>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}

            


</tbody>
              </table>
              <div className="d-grid gap-2">
    </div>   
      </Form>
      <Button variant="primary" style={{width:'100%'}} onClick={handleFormSubmit}>
       Envoyer
      </Button>   

        

          
            </div>

          ) : (
           
            <div className="mess" key={key}>
              <div className="ligne">
                <p style={{ fontWeight: '500', marginRight: '20px' }}>De: </p>
                <p>{message.LIBETAB}</p>
              </div>
              <div className="ligne">
                <p style={{ fontWeight: '500', marginRight: '20px' }}>Objet:</p>
                <p>{message.objet}</p>
              </div>
              <div className="ligne">
                <p style={{ fontWeight: '500', marginRight: '20px' }}>Message :</p>
                <p>{message.message}</p>
              </div>
              <div className="ligne">
                <p style={{ fontWeight: '500', marginRight: '20px' }}>Date :</p>
                <p>{ formatDate(message.date)}</p>

              </div>
              {message.file == '' ? (<></>):
              (<div className="ligne" style={{alignItems:'center'}}>
                <p style={{ fontWeight: '500', marginRight: '20px' }}>Piece Joint :</p>
                <p>
           
                <Button variant="primary" onClick={() => handleDownload(message.file)}>
      <FontAwesomeIcon style={{ marginRight: '10px', fontSize: '18px' }} icon={faCircleDown} />
      {message.file}
    </Button>
</p>
              </div>)
              }
            </div>
          )
        ))}

        </Card.Text>

      </Card.Body>
    </Card>
    
    
    </>
  );
}
