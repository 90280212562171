import { BrowserRouter, Routes, Route, NavLink, Outlet, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../css/messagerie.css';
import icon from '../../asset/send.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCircleCheck, faEye, faEyeSlash, faFloppyDisk,faRepeat } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import {
  CDBContainer
  
} from 'cdbreact';
export default function MessageS(props) {
  const server = props.server;
  axios.defaults.withCredentials = true;
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [popupDataLength, setPopupDataLength] = useState(0);
  const [selectedGrouM, setSelectedGrouM] = useState('');
  const [message, setMessage] = useState('');
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  const [load , setLoad] = useState(false);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${server}/messagesS`);
        setUsers(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [popupData, setPopupData] = useState([]);

  const handlePopupOpen = (grouM) => {
    const duplicates = users.filter((donnees) => donnees.grou_m === grouM);

    setPopupData(duplicates);
    setPopupDataLength(duplicates.length);

    setShow(true);

  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const uniqueData = users.filter((donnees, index, self) => {
    if (donnees.grou_m === null) {
      return true; // Include duplicates where grou_m is null
    } else {
      return self.findIndex((d) => d.grou_m === donnees.grou_m) === index;
    }
  });


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const id = uniqueData.map(item => item.id_r);
  console.log(id);
  const handleRelancer = async (messageId) => {
    try {
      // Update the message status here
      await axios.post(`${server}/relance/${messageId}`, {
        lu: '1' ,id// Update the 'lu' field to '1'
      });

      // Refetch the updated data from the server
      const res = await axios.get(`${server}/messagesS`);
      setUsers(res.data);


    } catch (error) {
      console.error(error);
      // Show an error message if the update fails
      alert('Failed to update message status.'+error);
    }
  };



  function formaterDate(dateString) {
    const date = new Date(dateString);
    
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hour = `0${date.getUTCHours()}`.slice(-2);
    const minute = `0${date.getUTCMinutes()}`.slice(-2);
  
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
  return (
    <>





<Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Liste</Modal.Title>
        </Modal.Header>
        <Modal.Body>             
           {popupData.map((donnees) => (
                <div key={donnees.id_m} style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:'20px' }}>
                  <p>{donnees.CODETAB} {donnees.LIBETAB}</p>
                  {
                    donnees.lu === 1 ? (
                      <Badge bg='success' pill>
                      <FontAwesomeIcon icon={faEye} />
                      </Badge>

                    ) : (
                      <Badge bg='danger' pill>
                      <FontAwesomeIcon icon={faEyeSlash} />
                      </Badge>

                    )

                  }
                </div>
              ))}
              </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           Fermer
          </Button>

        </Modal.Footer>
      </Modal>
      
      <Card style={{marginTop:'20px'}}>

<Card.Body>
  <Card.Title>
</Card.Title>
  <CDBContainer style={{maxHeight:'65vh',overflow:'auto'}}> 

    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
    <div><p style={{fontSize:'20px',fontWeight:'500'}}>Messages Envoyés</p></div>
<div style={{display:'flex'}}>  <Button variant="primary"  className="mb-3">Enregister</Button>{' '}
<Button variant="danger"  className="mb-3 ms-3">Supprimer </Button>{''}</div>


</div>

<div >
<Form>
<Form.Group className="mb-3" controlId="formGroupEmail">
  <Form.Control type="text" placeholder="Rechercher les messages..." />
</Form.Group>
</Form>

</div>

  <ListGroup as="ul" sty>




{load ? ( <> <div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'90%'}}>
    <svg className="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
     <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
    </div></>) : 
    ( 

    users =='' ? (<><h3 style={{color:'rgba(0,0,0,0.3)' ,display:'flex', alignItems:'center',justifyContent:'center',height:'90%' }}>-  aucun message  -</h3></>) : (
    
    <>         
    
    








{uniqueData.map((user) => {
    const duplicates = users.filter((donnees) => donnees.grou_m === user.grou_m);
    const popupDataLength = duplicates.length;
    const duplicatess = duplicates.filter((donnees) => donnees.lu =='1');
    return (<>
    
 <ListGroup.Item
as="li"
className={`d-flex justify-content-between align-items-start mail`}
key={user.id}
style={{listStyle:'none'}}
>
    <Form.Check
      inline
      type="checkbox"
    />
<div className="ms-3 me-auto">
<div className="fw-bold">{user.groupe === '' || user.groupe === null ?  (<>{user.role} {user.LIBETAB}({(user.nom )} {user.prenom})</>) : user.nom }</div>
{user.objet}

</div>
<div className='btnn btnsend'>


  {user.grou_m === null ? (<> 
  
    <Button bg='primary' size="sm"  onClick={() => handleRelancer(user.id_m)} > 
<FontAwesomeIcon icon={faRepeat} />
  </Button>{' '}
</>):(<>
  <Button bg='primary' size="sm"    onClick={() => handleRelancer(user.grou_m)}  > 
<FontAwesomeIcon icon={faRepeat} />
  </Button>{' '}
</>)}      
</div>
<div>

{user.groupe === '' ||user.groupe === null ? (
            user.lu == '1' ? (
<Badge bg="success" pill style={{float:'right'}}>
<FontAwesomeIcon  icon={faEye} />
</Badge>           
 ) : (
  <Badge bg="danger" pill style={{float:'right'}}>
  <FontAwesomeIcon  icon={faEyeSlash} />
  </Badge>      
            )
          ) : (
            <>

            <Badge bg="success" pill style={{float:'right' ,display:'flex',justifyContent:'center',alignItems:'center'}}  onClick={() => handlePopupOpen(user.grou_m)}>
    {duplicatess.length}/{popupDataLength}
              <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faUsers} />
  </Badge>  
            </>
          )}

<br></br>
<div style={{fontSize:'13px' , color:'rgba(0,0,0,0.5)' ,fontWeight:'500'}}>{formaterDate(user.date)}</div>
</div>
</ListGroup.Item>

    
    </>)

  })}

</>


))   
     }


</ListGroup>
</CDBContainer>

</Card.Body>
</Card>





    </>
  );
}
