import React, { useEffect, useState } from 'react';
import {BrowserRouter , Routes ,Route ,NavLink,Outlet,Link,useLocation} from 'react-router-dom';
import '../../css/navlink.css';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInbox ,faShareFromSquare,faFloppyDisk,faTrash} from '@fortawesome/free-solid-svg-icons';
export default function Navlink(){
  const location = useLocation();
  const [activeNavLink, setActiveNavLink] = useState('');

  useEffect(() => {
    // Mettez à jour l'état actif lorsque l'emplacement change
    setActiveNavLink(location.pathname);
  }, [location.pathname]);

    return(        
         <>
         <Card>
      <Card.Header style={{ justifyContent:'center'}} className='navlink'>
      <Nav variant="pills">
      <Nav.Item>
        <NavLink to="/Inbox/inbox" style={{ textDecoration: 'none' }}  >
          <Nav.Link href="#linkkss"  className={location.pathname === '/Inbox/inbox' ? 'active' : ''}>
            <FontAwesomeIcon icon={faInbox} style={{ marginRight: '10px' }} />Message Recu
          </Nav.Link>
        </NavLink>
      </Nav.Item>
      <NavLink to="/Inbox/send" style={{ textDecoration: 'none' }}>
        <Nav.Link href="#send"  className={location.pathname === '/Inbox/send' ? 'active' : ''}><FontAwesomeIcon icon={faShareFromSquare} style={{ marginRight: '10px' }} />Message envoyés</Nav.Link>
      </NavLink>
      <NavLink to="/Inbox/save" style={{ textDecoration: 'none' }}>
        <Nav.Item>
          <Nav.Link href="#link"  className={location.pathname === '/Inbox/save' ? 'active' : ''} > <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />Message enregistrés</Nav.Link>
        </Nav.Item>
      </NavLink>
      <NavLink to="/Inbox/trash" style={{ textDecoration: 'none' }}>
        <Nav.Item>
          <Nav.Link href="#linkks"  className={location.pathname === '/Inbox/trash' ? 'active' : ''}><FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />Corbeille</Nav.Link>
        </Nav.Item>
      </NavLink>
    </Nav>

      </Card.Header>

      <Card.Header style={{ justifyContent:'center'}} className='navlinkres'>
        <Nav variant="pills" defaultActiveKey="#first" >
          <Nav.Item>
          <NavLink to="/Inbox/inbox" style={{textDecoration:'none'}}>
          <Nav.Link href="#first"> <FontAwesomeIcon icon={faInbox} /> </Nav.Link>
            </NavLink>
          </Nav.Item>
          <NavLink to="/Inbox/send" style={{textDecoration:'none'}}>
          <Nav.Link href="#send"><FontAwesomeIcon icon={faShareFromSquare} /> </Nav.Link>
            </NavLink>
            <NavLink to="/Inbox/save" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#link" > <FontAwesomeIcon icon={faFloppyDisk} /> </Nav.Link>
          </Nav.Item>
          </NavLink>
          <Nav.Item>

          <NavLink to="/Inbox/trash" style={{textDecoration:'none'}}>
          <Nav.Item>
            <Nav.Link href="#linkks"><FontAwesomeIcon icon={faTrash} /></Nav.Link>
          </Nav.Item>
          </NavLink>

          </Nav.Item>
        </Nav>
      </Card.Header>
      </Card>

<Outlet/>

         </>
    )
}       